import { css } from "@emotion/react"

const globalStyle = css`
  html {
    overflow-x: hidden;
  }
  body {
    font-family: "Kanit", sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }
  img {
    margin-bottom: 0;
  }
  #___gatsby {
    overflow: hidden;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }
  #site__header__wrapper {
    z-index: 999;
  }
  .gb-container {
    width: 100%;
    max-width: 1590px;
    margin: auto;
    padding: 0 15px;
  }
  .gb--hidden--xl,
  .gb--hidden--lg,
  .gb--hidden--md,
  .gb--hidden--sm {
    display: block;
  }
  .gb--visible--xl,
  .gb--visible--lg,
  .gb--visible--md,
  .gb--visible--sm {
    display: none;
  }
  .gb--hidden {
    display: none;
  }
  .gb--absolute {
    position: absolute;
  }

  .ant-popover {
    width: 100%;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      border-radius: 0;
      background-color: #425766;
      box-shadow: none;
      .ant-menu {
        color: #fff;
        background: #425766;
        border: none;
        padding: 0;
        .ant-menu-item-selected {
          background-color: #7497b2;
        }
        .ant-menu-submenu {
          padding-bottom: 0;
          .ant-menu-submenu-title {
            font-family: "Kanit", sans-serif;
            font-weight: 400;
            font-size: 16px;
            padding-left: 20px !important;
            margin: 0;
          }
        }
        .ant-menu-submenu-selected {
          color: #fff;
          background-color: #7497b2;
        }
        .ant-menu-sub {
          background: #638199;
          .ant-menu-item {
            margin-top: 0;
            margin-bottom: 0;
            a {
              padding-left: 0;
            }
          }
        }
        .ant-menu-item {
          margin-top: 0;
          margin-bottom: 0;
          &:after {
            border-right: none;
          }
          a {
            font-family: "Kanit", sans-serif;
            font-weight: 400;
            font-size: 16px;
            color: #fff;
          }
        }
      }
      .ant-menu-submenu-arrow {
        color: #fff;
        right: 15px;
      }
      .ant-menu-item:hover,
      .ant-menu-item-active,
      .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
      .ant-menu-submenu-active,
      .ant-menu-submenu-title:hover {
        color: #fff;
        background: #7497b2;
        .ant-menu-submenu-arrow {
          color: #fff;
        }
      }
    }
    .ant-popover-inner-content {
      padding: 20px 0 30px 0;
      color: #fff;
      .langLinkWrapper {
        color: #fff;
        padding-top: 15px;
        padding-left: 20px;
        img {
          width: 18px;
          min-width: 18px;
          height: 18px;
          min-height: 18px;
          border-radius: 100%;
        }
        .anticon {
          margin-left: 10px;
          color: #fff;
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
  .ant-dropdown {
    .langSwitcher {
      padding: 0;
      li {
        padding-top: 12px;
        &.selected {
          background-color: #7497b2;
        }
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 0;
          padding: 0;
        }
      }
    }
    .subMenuWrapper {
      li {
        padding: 8px 20px;
        & > .ant-dropdown-menu-submenu-title {
          font-family: "Kanit", sans-serif;
          font-weight: 400;
          font-size: 15px;
        }
        &:hover {
          background-color: #7497b2;
          a {
            color: #fff;
          }
          & > .ant-dropdown-menu-submenu-title {
            color: #fff;
            background-color: #7497b2;
          }
        }
        a {
          font-family: "Kanit", sans-serif;
          font-weight: 400;
          font-size: 15px;
          color: #181818;
        }
      }
    }
  }
  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu-sub {
      .ant-dropdown-menu-item {
        padding: 8px 20px;
        a {
          font-family: "Kanit", sans-serif;
          font-weight: 400;
          font-size: 15px;
          color: #181818;
        }
        &:hover {
          background-color: #7497b2;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  .leaflet-container {
    .leaflet-popup-content {
      min-width: 240px;
      padding: 15px 0 5px 0;
    }
    .leaflet-popup {
      .popupTitle {
        font-family: "Kanit", sans-serif;
        font-weight: 400;
        font-size: 20px;
        color: #181818;
      }
      .popupText {
        font-family: "Kanit", sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: #646464;
      }
    }
  }
  .ant-pagination {
    .ant-pagination-disabled {
      button {
        display: inline-flex;
        align-items: center;
        border: none;
      }
    }
    .ant-pagination-item {
      border: none;
      a {
        color: #646464;
      }
      a[rel="prev"],
      a[rel="next"] {
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
      }
      &:hover {
        a {
          color: #7497b2;
        }
      }
    }
    .ant-pagination-item-active {
      font-weight: 700;
      background: #7497b2;
      border-radius: 100%;
      a {
        color: #fff;
        cursor: not-allowed;
        pointer-events: none;
      }
      &:hover {
        a {
          color: #fff;
        }
      }
    }
  }

  .CookieConsent {
    align-items: center !important;
    padding: 15px;
    background: #181818 !important;
    font-family: "Kanit", sans-serif;
    > div:first-of-type {
      flex: auto !important;
      font-weight: 400;
      font-size: 14px;
      max-width: 100%;
      margin-right: 15px !important;
      a {
        font-weight: 700;
        color: #7497b2;
        text-decoration: underline;
      }
    }
    > div:last-of-type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    button {
      min-width: 150px;
      height: 38px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 10px !important;
      padding: 0 10px !important;
      font-family: "Kanit", sans-serif;
      font-weight: 700;
      font-size: 15px;
      color: #fff !important;
      background-color: #7497b2 !important;
      border: 1px solid #7497b2 !important;
      border-radius: 80px !important;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
      text-align: center;
      text-decoration: none;
      transition: all 0.25s ease-in-out;
      outline: none;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
    #rcc-decline-button {
      background-color: transparent !important;
      border: 1px solid #fff !important;
      filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.15));
    }
  }

  @media only screen and (min-width: 576px) {
    .gb-container {
      padding: 0 30px;
    }
    .gb--hidden--sm {
      display: none;
    }
    .gb--visible--sm {
      display: block;
    }
    .ant-popover {
      .ant-popover-inner {
        .ant-menu {
          .ant-menu-submenu {
            padding: 0;
            .ant-menu-submenu-title {
              padding-left: 40px !important;
            }
          }
          .ant-menu-sub {
            .ant-menu-item {
              a {
                padding-left: 20px;
              }
            }
          }
          .ant-menu-item {
            padding-left: 40px !important;
          }
        }
        .ant-menu-submenu-arrow {
          right: 30px;
        }
      }
      .ant-popover-inner-content {
        .langLinkWrapper {
          padding-left: 40px;
        }
      }
    }
    .CookieConsent {
      padding: 10px 30px;
    }
  }
  @media only screen and (min-width: 768px) {
    .gb--hidden--md {
      display: none;
    }
    .gb--visible--md {
      display: block;
    }
  }
  @media only screen and (min-width: 992px) {
    .gb--hidden--lg {
      display: none;
    }
    .gb--visible--lg {
      display: block;
    }
    .CookieConsent {
      padding: 20px 30px;
      > div:first-of-type {
        max-width: 538px;
        margin-right: 30px !important;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .gb--hidden--xl {
      display: none;
    }
    .gb--visible--xl {
      display: block;
    }
    /*.CookieConsent {
      padding: 20px 12%;
    }*/
  }
  @media only screen and (min-width: 1440px) {
    .ant-dropdown {
      .subMenuWrapper {
        li {
          & > .ant-dropdown-menu-submenu-title {
            font-size: 16px;
          }
          a {
            font-size: 16px;
          }
        }
      }
    }
    .ant-dropdown-menu-submenu {
      .ant-dropdown-menu-sub {
        .ant-dropdown-menu-item {
          a {
            font-size: 16px;
          }
        }
      }
    }
    /*.CookieConsent {
      padding: 20px 15%;
    }*/
  }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
  }
  @media only screen and (max-width: 320px) {
  }
  /* Modal home page */
  .ant-modal {
    width: 1140px !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-close-x {
    background-color: #fff;
    width: 50px;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
  }
`

export default globalStyle
