import React, { Fragment } from "react"
import { useIntl, Link } from "gatsby-plugin-react-intl"
import { Global, css } from "@emotion/react"
import PropTypes from "prop-types"
import "antd/dist/antd.css"
import globalStyle from "../styles/global"
import Pwa from "./Pwa"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
  const intl = useIntl()
  const locale = intl.locale

  return (
    <Fragment>
      <Global
        styles={css`
          ${globalStyle}
        `}
      />
      {children}
      <Pwa />
      {locale === `th` ? (
        <CookieConsent
          enableDeclineButton
          flipButtons
          location="bottom"
          buttonText="ยอมรับ"
          declineButtonText="ปฏิเสธ"
          cookieName="gatsby-gdpr-google-analytics"
        >
          เราใช้คุกกี้เพื่อเพิ่มประสบการณ์ที่ดีในการใช้เว็บไซต์
          แสดงเนื้อหาและโฆษณาให้ตรงกับความสนใจ
          รวมถึงเพื่อวิเคราะห์การเข้าใช้งานเว็บไซต์และทำความเข้าใจว่าผู้ใช้งานมาจากที่ใด{" "}
          <br className="gb--visible--lg" />
          อ่าน <Link to="/cookie-policy/">นโยบายคุกกี้</Link> เพิ่มเติม
        </CookieConsent>
      ) : (
        <CookieConsent
          enableDeclineButton
          flipButtons
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
        >
          We use cookies to enhance your website experience. Show content and
          ads tailored to your interests. This includes to analyze website
          traffic and understand where users are coming from.{" "}
          <br className="gb--visible--lg" />
          Read <Link to="/cookie-policy/">Cookies Policy</Link>
        </CookieConsent>
      )}
    </Fragment>
  )
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
